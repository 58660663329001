import React, { useState } from 'react';

import {
  Alert16Regular,
  Folder16Filled,
  Folder16Regular,
  Home16Regular,
  Note16Regular,
  Print16Regular,
  Warning16Filled,
} from '@fluentui/react-icons';
import { Popconfirm, Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { apiService } from '../../../utills/api';
import PrintSettingsModal from '../modals/PrintSettingsModal';
import './DocTree.css';
import Wrap from './Wrap';
import { CloseSquareO, MinusSquareO, PlusSquareO } from './icons';
import PartModal from '../modals/PartModal';
import { NotificationManager } from 'react-notifications';

const styles = {
  tree: {
    position: 'relative',
    // padding: "4px 0px 4px 0px",
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    verticalAlign: 'middle',
  },
  toggle: {
    width: '1em',
    height: '1em',
    // marginRight: 10,
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  type: {
    textTransform: 'uppercase',
    fontFamily: 'monospace',
    fontSize: '0.6em',
    verticalAlign: 'middle',
  },
  contents: {
    willChange: 'transform, opacity, height',
    // marginLeft: 6,
    padding: '2.5px 0px 0px 28px',
    // borderLeft: "1px dashed gray"
    // borderBottom: "0.2px solid gray"
  },

  controls: {
    float: 'right',
    paddingRight: '10px',
    justifyContent: 'space-between',
  },
};

const Contents = ({ children, ...style }, open, visible) => {
  let newStyle = open
    ? { opacity: 1, height: 'auto', ...style }
    : { opacity: 0, height: 0, ...style };

  return <div style={{ ...newStyle, ...styles.contents }}>{children}</div>;
};

export const TreeItemButton = ({ onClick, text }) => (
  <button
    className="border rounded-md px-2 text-sm text-gray-600 hover:text-color6 hover:border-color6"
    onClick={onClick}
  >
    {text}
  </button>
);

const TreeItem = (props) => {
  const [open, setOpen] = useState(props.open);
  const [visible, setVisible] = useState(props.visible);
  const [immediate, setImmediate] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const { modelId, contractId } = useSelector((state) => state.contract);
  const { current_company } = useSelector((state) => state.auth);
  const queryClient = useQueryClient();
  const [partModalOpen, setPartModalOpen] = useState(false);

  const deleteDocument = useMutation({
    mutationFn: ({ id }) => {
      return apiService.delete(`/api/v1/document/${id}/remove`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['documentationTree', props.documentationId]);
    },
  });

  const updateDocPart = useMutation({
    mutationFn: ({ id, values }) => {
      console.log({ id, values });
      return apiService.patch(`/api/v1/document/${id}/`, {
        ...values,
      });
    },
    onSuccess: ({ data }) => {
      //TODO mrknout proc to freezen appku
      // queryClient.setQueryData(['documentationTree', activeDocumentationId], (oldData) => {
      //   return addItemToTree(oldData, data);
      // });

      queryClient.invalidateQueries(['documentationTree', props.documentationId]);
      setPartModalOpen(false);
    },
    onError: (error) => {
      NotificationManager.error('Chyba', 'Položku se nepodařilo uložit.');
    },
  });

  const editFilter = useMutation({
    mutationFn: ({ id, values }) => {
      return apiService.patch(`/api/v1/filter/${id}/`, values);
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(['filter', id]);
      setSettingsModalOpen(false);
    },
  });

  const createFilter = useMutation({
    mutationFn: ({ values }) => {
      return apiService.post(`/api/v1/filter/`, {
        ...values,
        model: modelId,
        contract: contractId,
        company: current_company,
        document: props.documentId,
      });
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['documentationTree', props.documentationId], () => {
        const newTree = props.updateFilter(data.id);

        return newTree;
      });
      setSettingsModalOpen(false);
    },
  });

  const handleEditDocument = (id, values) => {
    updateDocPart.mutate({ id, values });
  };

  const toggle = () => {
    if (!children) {
      return;
    }

    setOpen((open) => !open);
    setImmediate(false);
  };

  const toggleVisibility = () => {
    setVisible((visible) => !visible);
    setImmediate(true);

    props.onClick?.(visible);
  };

  const { children, content, type, style, controls, tasks, status, filterId, item } = props;

  const handleSubmit = (filterId, values) => {
    if (filterId) {
      editFilter.mutate({ id: filterId, values });
    } else {
      createFilter.mutate({ values });
    }
  };

  const IconsAntd = () => {
    switch (type) {
      case 'PA':
        return <Folder16Regular />;
      case 'DR':
        return <Note16Regular />;
      case 'BO':
        return <Home16Regular />;
      case 'SH':
        return <Print16Regular />;
      case 'PR':
        return <Alert16Regular />;
      case 'SE':
        return <Folder16Regular />;
      default:
        return <Home16Regular />;
    }
  };

  const Icon = children ? (open ? MinusSquareO : PlusSquareO) : CloseSquareO;
  return (
    <div style={{ ...styles.tree, ...style }} className="treeview w-full">
      <div className="flex items-center bg-white">
        <Wrap padding={'7px'} float={true}>
          <Icon
            className="toggle"
            style={{ ...styles.toggle, opacity: 1 }}
            onClick={() => toggle()}
          />
        </Wrap>
        <Wrap padding={'7px'} float={true}>
          {IconsAntd()}
        </Wrap>

        <div className="text-sm px-2">{content}</div>

        <div>{status && status !== 'R' ? <Spin /> : ''}</div>
        <div>{status && status === 'E' ? <Warning16Filled /> : ''}</div>

        <div>
          {tasks?.map((task, i) => {
            if (task?.file) {
              return (
                <a key={i} href={task.file} target="_blank" rel="noreferrer">
                  <Folder16Filled />
                </a>
              );
            }
          })}
        </div>

        <div className="px-2 ml-auto items-center flex gap-1">
          {type == 'SH' && (
            <TreeItemButton onClick={() => setSettingsModalOpen(true)} text="Nastavení tisku" />
          )}

          {controls}
          {!children && (
            <Popconfirm
              placement="topLeft"
              title="Opravdu chcete položku odstranit?"
              onConfirm={() => deleteDocument.mutate({ id: props.documentId })}
              okText="Ano"
              cancelText="Ne"
            >
              <TreeItemButton text="Odstranit" />
            </Popconfirm>
          )}

          {type !== 'SH' && (
            <TreeItemButton onClick={() => setPartModalOpen(true)} text="Upravit" />
          )}
        </div>
      </div>

      {Contents(props, open, visible)}

      {settingsModalOpen && (
        <PrintSettingsModal
          visible={settingsModalOpen}
          filterId={filterId}
          onSubmit={handleSubmit}
          document={props.item}
          onClose={() => {
            setSettingsModalOpen(false);
          }}
        />
      )}

      {partModalOpen && (
        <PartModal
          documentationId={props.documentationId}
          visible={partModalOpen}
          onSubmit={handleEditDocument}
          formProps={item}
          onClose={() => {
            setPartModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default TreeItem;
